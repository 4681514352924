import {DataSourceType} from './DataSourceType';
import {ClassedEntity} from '../../../../domain/ClassedEntity';
import {Reference} from '../../../../domain/Reference';
import {SelectableRef} from '../../../../domain/SelectableRef';
import {Transform, Type} from 'class-transformer';
import {ReferenceTransformer} from '../../../../domain/transformation/ReferenceTransformer';
import {Company} from '../../../core/domain/company/Company';
import {DataSourceUpdateType} from './DataSourceUpdateType';
import {DataMergeState} from '../_import/data-merge/DataMergeState';
import {UpdatedRecord} from './ActivityCardControlSource';

export class DataSource extends ClassedEntity {
  public static readonly MODULE: string = 'blacklist-pep';
  public static readonly COLLECTION: string = 'dataSource';

  public key: string;
  public type: DataSourceType;
  public name: string;
  public url: string;
  public description: string;
  // TODO : Change lastUpdate in AuditData
  @Type(() => Date)
  public lastUpdate: Date;
  public comment: string;
  @Transform(ReferenceTransformer.transformTo(Company))
  public authorizedCompanies: Reference<Company>[] = [];
  public byDefault: boolean;
  public updateType: DataSourceUpdateType;
  @Transform(ReferenceTransformer.transformTo(Company))
  public companyRef: Reference<Company>;
  public count: number;
  public dataMergeState: DataMergeState;
  public lastUpdatedRecords: UpdatedRecord;
  public flagBlobId: string;
  public iconBlobId: string;
  public label: string;
  public previewSessionId: string;
  public isObsolete: boolean = false;
  public tags: string[];
  public anonymous: boolean = false


  constructor() {
    super('com.beclm.blacklist.pep.api.dataSource.DataSource');
  }

  public static toSelectableRef(
    reference: Reference<DataSource>
  ): SelectableRef<DataSource> {
    const selectableRef = new SelectableRef<DataSource>();
    selectableRef.id = reference;
    if (reference.isResolved()) {
      selectableRef.__label = reference.entity.key;
    } else {
      selectableRef.__label = reference.id;
    }
    return selectableRef;
  }

  public toSelectableRef(): SelectableRef<DataSource> {
    const selectableRef = new SelectableRef<DataSource>();
    selectableRef.id = new Reference<DataSource>(this);
    if (this.type === DataSourceType.PERSONS) {
      selectableRef.__label = this.label;
    } else {
      selectableRef.__label = this.name;
    }
    return selectableRef;
  }
}
